import SectionHeading from "../components/Typography/SectionHeading";
import "./index.css";

function About() {
  return (
    <section id="about" className="container pt-16 tablet:py-0">
      <SectionHeading text="About"></SectionHeading>
      <div className="flex flex-col justify-center">
        <div className="p-10 tracking-wider font-semibold">
          <p className="py-2">
            Embarking on my coding journey in mid-2017, I've discovered a
            profound love for learning and a passion for leveraging technology
            to make a positive impact.
          </p>
          <p className="py-2">
            Beyond the code, my heart lies in crafting solutions that extend
            beyond mere functionality. Whether it's designing robust APIs,
            creating modular frontends, or contributing to contract work for
            digital agencies, the thrill of problem-solving and refining code is
            matched only by my desire to create meaningful, user-centric
            experiences.
          </p>
          <p className="py-2">
            In the ever-shifting tech landscape, I embrace the role of perpetual
            student, always hungry for knowledge and open to the lessons each
            challenge brings. I don't claim perfection—rather, I see each
            stumbling block as an opportunity to learn and grow.
          </p>
          <p className="py-2">
            Every line of code in my portfolio tells a story of learning,
            growth, and a sincere aspiration to contribute to a tech-driven
            world with humility and a willingness to embrace imperfection.
          </p>
        </div>
        <img
          className="image"
          src="IMG_2564.JPG"
          alt="Portrait of Vanessa Martin"
        />
      </div>
    </section>
  );
}

export default About;
