import { Card, CardBody, Image } from "@nextui-org/react";
import SectionHeading from "../components/Typography/SectionHeading";

const projects = [
  {
    title: "Vyro",
    link: "https://www.vyro.com.au/",
    description:
      "Virtual electric car dealership allowing users to simplify the entire purchase process of an electric vehicle.",
  },
  {
    title: "Dazychain",
    link: "https://www.dazychain.com/",
    description:
      "A legal platform allowing internal legal teams to outsource, manage, and invoice legal matters.",
  },
  {
    title: "FOUR App via Thirty4",
    link: "https://www.thirty4.com/",
    description:
      "A platform with componentised solutions helping solve logistic issues in supply chain companies.",
  },
  {
    title: "SA Museum",
    link: "https://www.samuseum.sa.gov.au/",
    description:
      "Website for South Australia's largest collection of cultural and National history.",
  },
  {
    title: "Hendercare",
    link: "https://www.hendercare.com.au/",
    description:
      "Internal Booking System WebApp to help manage Hendercare, an aged care and disability service provider.",
  },
];

function Work() {
  return (
    <section id="work" className="container pt-16 tablet:py-0">
      <div>
        <SectionHeading text="Work"></SectionHeading>

        <div className="flex flex-col tablet:flex-wrap tablet:flex-row justify-center desktop:flex-nowrap">
          {projects.map((project, index) => (
            <Card key={index} className="py-4 m-6 tablet:mx-4 tablet:w-1/3">
              <CardBody className="py-2 h-full">
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="normal-case"
                >
                  <Image
                    alt={`${project.title} Background Image`}
                    className="object-cover rounded-xl h-full"
                    src={`${project.title}.png`}
                  />
                  <h4 className="pt-4 font-bold text-large">{project.title}</h4>
                  <p>{project.description}</p>
                </a>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Work;
