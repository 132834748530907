interface SubheadingProps {
  text: string;
}

function Subheading({ text }: SubheadingProps) {
  return (
    <h3 className="normal-case  tracking-wider text-center font-thin">
      {text}
    </h3>
  );
}

export default Subheading;
