import { Image } from "@nextui-org/react";

function Logo() {
  return (
    <div>
      <Image
        className="object-cover"
        width={100}
        src="../../faviconLogo.svg"
        alt="Vanessa Martin Logo"
      />
    </div>
  );
}

export default Logo;
