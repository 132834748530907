function Footer() {
  return (
    <div className="container flex items-center py-8 px-6">
      <div className="w-1/2">&#169; 2024 Vanessa Martin</div>
      <div className="w-1/2 flex justify-end">
        <a
          href="https://www.linkedin.com/in/vanessaeunicemartin/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-11 mr-2" src="linkedin.svg" alt="" />
        </a>
        <a
          href="https://github.com/Atlas-Quest"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-10 mr-2" src="github.svg" alt="" />
        </a>
        {/* <a
          href="https://www.instagram.com/vanessamartin.js/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-10" src="instagram.svg" alt="" />
        </a> */}
      </div>
    </div>
  );
}

export default Footer;
