import { NextUIProvider } from "@nextui-org/react";
import Nav from "./Navbar";
import MobileNavbar from "./Navbar/mobile";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";
import Footer from "./components/Footer";
import Banner from "./Banner";

function App() {
  return (
    <NextUIProvider>
      <Nav></Nav>
      <MobileNavbar></MobileNavbar>
      <div className="scroll-container">
        <Banner></Banner>
        <About></About>
        <Work></Work>
        <Contact></Contact>
      </div>
      <Footer></Footer>
    </NextUIProvider>
  );
}

export default App;
