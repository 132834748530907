import React from "react";
import SectionHeading from "../components/Typography/SectionHeading";
import Subheading from "../components/Typography/Subheading";
import { Input, Textarea, Button } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

interface Message {
  name: string;
  email: string;
  subject: string;
  message: string;
}

function Contact() {
  const [formData, setFormData] = useState<Message>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [enquirySuccess, setEnquirySuccess] = useState(Boolean);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInvalid, setIsInvalid] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));

    const isEmailInvalid = name === "email" && !isValidEmail(value);

    setIsInvalid((prevInvalidity) => ({
      ...prevInvalidity,
      [name]: value === "" || isEmailInvalid,
    }));

    // Check if any field is empty
    setIsDisabled(
      Object.values(formData).some((val) => val === "") ||
        Object.values(isInvalid).some((invalid) => invalid)
    );
  };

  const isValidEmail = (email: string): boolean => {
    return email.includes("@");
  };

  const handleSubmit = (formData: Message) => {
    const { name, subject, email, message } = formData;

    if (name === "" || subject === "" || email === "" || message === "") {
      toast.error("Please fill in the required fields.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    fetch(`https://api.vanessamartin.tech/api/email/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, subject, email, message }),
    }).then((response) => {
      if (response) {
        setEnquirySuccess(true);
      }

      return response.json();
    });
  };

  const successMessage = (
    <div className="flex justify-center p-8 text-center">
      Thank you for reaching out! I'll make it a priority to connect with you
      soon.
    </div>
  );

  return (
    <div id="contact" className="container py-16">
      <SectionHeading text="Contact"></SectionHeading>
      <ToastContainer />
      {enquirySuccess ? (
        successMessage
      ) : (
        <div className="flex flex-col-reverse px-6 mb-16 tablet:flex-row">
          <div className="w-full">
            <div className="flex flex-col justify-center pb-6 items-center w-full">
              <Subheading text="Connect with me and let's turn ideas into reality. Your next project starts here!"></Subheading>
            </div>
            <div className="flex">
              <Input
                className="mb-4 mr-4"
                variant="underlined"
                type="text"
                id="name"
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                isInvalid={isInvalid.name}
              />
              <Input
                variant="underlined"
                type="email"
                id="email"
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={isInvalid.email}
              />
            </div>

            <Input
              className="mb-4"
              variant="underlined"
              type="text"
              id="subject"
              name="subject"
              label="Subject"
              value={formData.subject}
              onChange={handleChange}
              isInvalid={isInvalid.subject}
            />

            <Textarea
              className="mb-4"
              radius="none"
              variant="flat"
              id="message"
              name="message"
              label="Message"
              value={formData.message}
              onChange={handleChange}
              isInvalid={isInvalid.message}
            />
            <div className="flex justify-end">
              <Button
                className="mt-4 border-2 border-black bg-white p-6 text-black rounded-none uppercase hover:bg-black hover:text-white tracking-widest hover:opacity-100 transition duration-300 ease-in-out"
                radius="none"
                type="submit"
                value="Submit"
                isDisabled={isDisabled}
                onPress={() => handleSubmit(formData)}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
