import { Link } from "@nextui-org/react";

interface CTAButtonProps {
  text: string;
  inverted?: boolean;
  link: string;
}

function CTAButton({ text, link, inverted }: CTAButtonProps) {
  return (
    <Link
      disableAnimation
      className={
        inverted
          ? "border-2 border-black p-4 text-black rounded-none uppercase hover:bg-black hover:text-white tracking-widest hover:opacity-100 transition duration-300 ease-in-out"
          : "border-2 border-white p-4 text-white rounded-none uppercase hover:bg-white hover:text-black tracking-widest hover:opacity-100 transition duration-300 ease-in-out"
      }
      href={link}
    >
      {text}
    </Link>
  );
}

export default CTAButton;
