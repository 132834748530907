import CTAButton from "../components/Button";
import "./index.css";

function Banner() {
  return (
    <div className="main-banner flex justify-center items-center bg-black text-white">
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-4xl desktop:text-7xl pb-4">
          Vanessa Martin
        </h2>
        <h2 className="font-bold text-3xl desktop:text-4xl pb-6">
          Software Engineer
        </h2>
        <CTAButton text="View my work" link="/#work"></CTAButton>
      </div>
    </div>
  );
}

export default Banner;
