interface SectionHeadingProps {
  text: string;
}

function SectionHeading({ text }: SectionHeadingProps) {
  return (
    <h2 className="pt-4 pb-6 tablet:py-16 font-bold text-2xl tablet:text-4xl text-center tracking-wider">
      {text}
    </h2>
  );
}

export default SectionHeading;
