import {
  Navbar,
  NavbarBrand,
  Link,
  NavbarMenuToggle,
  NavbarContent,
  NavbarMenuItem,
  NavbarMenu,
} from "@nextui-org/react";
import Logo from "../../components/Logo";
import { useCallback, useEffect, useState } from "react";

function MobileNavbar() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const [activeSection, setActiveSection] = useState(null);
  const menuItems = ["about", "work", "contact"];

  const handleScroll = useCallback(() => {
    const currentScrollPosition = window.scrollY;
    const isScrollingUp =
      currentScrollPosition < scrollPosition || currentScrollPosition < 50;

    setScrollPosition(currentScrollPosition);

    if (!isMenuOpen) {
      setIsMenuOpen(false);
      setVisible(isScrollingUp);
    }
  }, [scrollPosition, isMenuOpen, setIsMenuOpen, setVisible]);

  useEffect(() => {
    const handleScrollEvent = () => handleScroll();

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [handleScroll]);

  const handleClick = (item: any) => {
    setActiveSection(item);
    setIsMenuOpen(false);
  };

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      className={`laptop:hidden navbar bg-white ${
        visible ? "top-0" : "-top-30"
      }`}
    >
      <NavbarContent justify="start">
        <NavbarBrand>
          <Link href="/">
            <Logo></Logo>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>

      <NavbarMenu className="p-16 bg-white">
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            key={`${item}-${index}`}
            isActive={activeSection === item}
          >
            <Link
              className="w-full pt-4 py-4 text-2xl text-black tracking-wider"
              href={`#${item}`}
              size="lg"
              onClick={() => handleClick(item)}
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}

export default MobileNavbar;
