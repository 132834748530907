import {
  Navbar,
  NavbarContent,
  NavbarBrand,
  NavbarItem,
  Link,
} from "@nextui-org/react";
import CTAButton from "../components/Button";
import Logo from "../components/Logo";
import "./index.css";
import { useCallback, useEffect, useState } from "react";

function Nav() {
  const [prevScrollPosition, setPrevScrollPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollPosition = window.scrollY;
    const isScrollingUp = prevScrollPosition > currentScrollPosition;

    setPrevScrollPosition(currentScrollPosition);
    setVisible(isScrollingUp);
  }, [prevScrollPosition]);

  useEffect(() => {
    const handleScrollEvent = () => handleScroll();

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [handleScroll]);

  return (
    <Navbar
      isBordered
      className={`hidden laptop:flex navbar stroke tracking-widest ${
        visible ? "top-0" : "-top-30"
      }`}
    >
      <NavbarContent>
        <NavbarBrand>
          <Link href="/">
            <Logo></Logo>
          </Link>
        </NavbarBrand>
        <NavbarItem className="mr-6">
          <Link className="text-black hover:opacity-100" href="/#about">
            About
          </Link>
        </NavbarItem>
        <NavbarItem className="mr-6">
          <Link className="text-black hover:opacity-100" href="/#work">
            Work
          </Link>
        </NavbarItem>
        {/* <NavbarItem>
          <Link className="text-black hover:opacity-100" href="#blog">
            Blog
          </Link>
        </NavbarItem> */}
      </NavbarContent>
      <NavbarItem>
        <CTAButton text="Contact" link="/#contact" inverted></CTAButton>
      </NavbarItem>
    </Navbar>
  );
}

export default Nav;
